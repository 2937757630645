import React from 'react';
import QRCode from 'react-qr-code';


const LessonQRCode = ({ lesson }) => {
  // Extract required data for QR code
  const qrData = {
    staffLessonId: lesson.data[0]?.chapter_id || 0,
    classroomId: lesson.data[0].icc_tracking.classroom_id,
    staffId: lesson.data[0].staff_id,
    subjectId: lesson.data[0].subject_id,
    subjectSubId: lesson.data[0].subject_sub_id,
    chapterId: lesson.data[0]?.icc_day || 0,
    academicYearId: lesson.data[0].institute_academic_year_id
  };

  console.log("lessson is ", lesson)
  console.log("QR DATA IS : ",qrData);
  // Convert data to JSON string for QR code
  const qrContent = JSON.stringify(qrData);

  return (
    <div className="flex flex-col items-center p-4">
      <QRCode 
        value={qrContent}
        size={500}
        level="H"
        
      />
      <p className="mt-2 text-sm text-gray-600">Scan to view lesson details</p>
    </div>
  );
};

export default LessonQRCode